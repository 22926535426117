import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ScrollToTop from './components/ScrollToTop';

import ReactGA from 'react-ga';
import ScrollUpBtn from './components/ScrollUpBtn';

import Home from './pages/Home';
import About from './pages/About';
// import Testimonials from './pages/testimonials';
// import Calculators from './pages/calculators';
import Programs from './pages/LoanPrograms';
import Fha from './pages/fha';
import Listing from './pages/Listing.js';
import Contact from './pages/Contact';
import Sitemap from './pages/Sitemap';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Fade from "react-bootstrap/Fade";
import Modal from "react-bootstrap/Modal";
import GenerateLead from "./components/GenerateLead";



function App() {

  useEffect(() => {
    ReactGA.initialize('UA-187484893-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);
  const [modalShow, setModalShow] = useState(false);
  const handleModal = () => setModalShow(false);


  return (
    <React.Fragment>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <div id="ModalWrapper">
          <Fade in={modalShow}>
            <Modal size="lg" show={modalShow} onHide={handleModal} className="globalApplyModal">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <GenerateLead />
              </Modal.Body>
            </Modal>
          </Fade>
        </div>
        <Header setModalShow={setModalShow} />
        <Route render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={250}
              classNames="fade">
              <Switch location={location}>

                {/* <Route exact path="/" component={Home} /> */}
                {/* <Redirect from="/index.html" to="/" />  */}


                <Route path="/about" component={About} />
                {/* <Route path="/testimonials" component={Testimonials} /> */}
                {/* <Route exact path="/calculators" >
                  <Calculators setModalShow={setModalShow} />
                </Route> */}
                <Route path="/listingreport" component={Listing} />
                <Route exact path="/loan-programs" >
                  <Programs setModalShow={setModalShow} />
                </Route>
                <Route path="/fha" component={Fha} />
                <Route exact path="/contact" >
                  <Contact setModalShow={setModalShow} />
                </Route>
                <Route exact path="/sitemap" >
                  <Sitemap setModalShow={setModalShow} />
                </Route>
                {/*<Route component={Error} /> */}

                <Route exact path="/home">
                  <Home setModalShow={setModalShow} />
                </Route>
                <Redirect from="/" to="/home" />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
        <ScrollUpBtn />
        <Footer setModalShow={setModalShow} />
      </Router>
    </React.Fragment>
  );
}

export default App;
