/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import { Helmet } from "react-helmet";
import axios from "axios";
import triangles from "../images/triangles.png";
import lines from "../images/dotted-line.png";
import homescout from "../images/homescout-white.png";
import approveIcon from "../images/approve-icon.png";
import group900 from "../images/Group 900.png";
import homeSearch from "../images/start-search-icon.png";
import teamIcon from "../images/team-icon.png";
import starsIcon from "../images/Group 901.png";
import dots from "../images/dots.png";
import ReactGA from "react-ga";
import useBlogs from "../Hooks/useBlogs";

import "../css/home.css";

export default function Home(props) {
  const imgCDNURL = "https://prod-cdn.swmc.com/";
  const {blogPosts, handleRefresh, loading, error} = useBlogs({ type: "GET_ALL_BLOGS" });
  // Checking if blogs is not null and has data to avoid potential errors
  // if (blogPosts) {
  //   console.log("Blogs data:", blogPosts);
  // } else {
  //   console.log("No blogs data found.");
  // }

  let dateOptions = { month: "numeric", day: "numeric", year: "numeric" };

  // useEffect(() => {
  //   ReactGA.initialize("UA-187484893-1");
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   console.log("blog post requested");
  //   axios
  //     .post(
  //       "https://c3lpelw90h.execute-api.us-west-1.amazonaws.com/default/swmc-blog-production"
  //     )
  //     .then((response) => {
  //       setBlogPosts(response.data);
  //       console.log("Blog posts responce", response.data);
  //     });
  // }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Homebuyer Connect</title>
        <link rel="canonical" href="https://homebuyerconnect.com/" />
      </Helmet>
      <Container fluid className="home-hero">
        <Container className="h-75 px-0 pb-5">
          <Row className="hero-contents">
            <Col className="px-0 hero-text" lg={9} xl={7 }>
              <h1 className="px-2 d-inline">
                Connecting<span className="blue-rectangle pb-0"></span>
              </h1>
              <p className="d-inline h2">
                You to What Matters Most on Your Homebuying{" "}
                <span className="lucky navy-blue">Journey</span>
              </p>
              <br />
              <div className="pt-3">
                <Button variant="primary" className="dblue clrd-btn text-white clrd-btn text-white ms-0 px-5" onClick={() => {
                      props.setModalShow(true);
                    }} >
               Get Started
              </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="w-75">
        <Row>
          <Col className="text-center">
            <Image className="triangleImage" src={triangles} />
          </Col>
        </Row>
        <Row className="box-wrapper pt-5 pr-0 mt-3 mt-sm-5">
          <div className="medium-text">
            <Col lg={12} className="pb-2">
              <h2 className="page-title">What Matters Most To You?</h2>
            </Col>
            <Col lg={12}>
              <p className="sub-title">
                It&#39;s our job to give you a stress-free mortgage
              </p>
            </Col>
            <Col lg={12}>
              <p>
                Is it finding your dream home, perfect for raising a family? A
                great rate and a quick no-hassle mortgage? A backyard fit for
                Fido? Personalized, expert service from a local loan officer and
                Realtor team?
              </p>
              <p>
                Whatever it is… Homebuyer Connect will guide you every step of
                the way.
              </p>
            </Col>
          </div>
          <Col className="home-box">
            <div className="large-text">
              <Col lg={9} xl={6} className="pb-2">
                <h2 className="page-title">What Matters Most To You?</h2>
              </Col>
              <Col lg={6} className="pb-3">
                <h4 className="sub-title">
                  It&#39;s our job to give you a stress-free mortgage
                </h4>
              </Col>
              <Col lg={5}>
                <p>
                  Is it finding your dream home, perfect for raising a family? A
                  great rate and a quick no-hassle mortgage? A backyard fit for
                  Fido? Personalized, expert service from a local loan officer
                  and Realtor team?
                </p>
                <p>
                  Whatever it is… Homebuyer Connect will guide you every step of
                  the way.
                </p>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="blue-box-container">
        <Row
          className="mb-1 mb-md-5 box-row"
          style={{ position: "relative", zIndex: "1" }}
        >
          <div className="boxes-curver">
            <Image className="w-100" src={lines} />
          </div>
          <Col sm={12} md={6} lg={3} xl={3} className="blue-box shadow-lg px-1">
            <p className="home-numbers">1</p>
            <div className="text-center box-contents">
              <Image fluid className="boxIcon" src={homeSearch} />
              <h4 className="pb-1 pb-sm-2 pb-md-2">Start Your Home Search</h4>
              {/* <Image fluid src={homescout} /> */}

            </div>
          </Col>
          <Col sm={11} md={6} lg={3} xl={3} className="blue-box2 shadow-lg">
            <p className="home-numbers">2</p>
            <div className="text-center box-contents">
              <Image fluid className="boxIcon" src={approveIcon} />
              <h4>Get TRU Approved</h4>
              <p>
                 <Button variant="primary" className="dblue clrd-btn text-white" onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
              </p>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={3}
            xl={3}
            className="blue-box shadow-lg bottom-box"
          >
            <p className="home-numbers">3</p>
            <div className="text-center box-contents">
              <Image fluid className="boxIcon" src={teamIcon} />
              <h4>Work with a Local Team</h4>
              <p className="box-sub pt-1 px-xl-3">
                An experienced Market Manager and Realtor combo
              </p>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={3}
            xl={3}
            className="blue-box2 shadow-lg bottom-box"
          >
            <p className="home-numbers">4</p>
            <div className="text-center box-contents">
              <Image fluid className="boxIcon" src={group900} />
              <h2 className="pb-2">Enjoy</h2>
              <h4>Your Dream Home</h4>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="w-75">
        <Row className="d-flex justify-content-center mt-4 py-5">
          <Col className="text-center" lg={6}>
            <h2 className="page-title">From Homebuyers</h2>
            <h2 className="page-title rect-title">
              <span
                style={{ position: "inherit" }}
                className="light-blue-rectangle px-1"
              >
                Just Like You
              </span>
            </h2>
          </Col>
        </Row>

        <Row className="reviews-row mb-3">
          {/* <CardDeck> */}
          <Card as={Col} md={12} lg={4} className="review-box">
            <Card.Body>
              <p className="pt-4 pb-0">
                <Image src={starsIcon} className="img-fluid" alt="Stars Icon" />
              </p>
              <p className="review">
                &quot;From start to finish, on the closing of our dream home,
                Sun West has been extraordinary. In these uncertain times, they
                managed to pull off a flawless closing&quot;
              </p>
              <p className="user-review">Robert C.</p>
            </Card.Body>
          </Card>
          <Card as={Col} md={12} lg={4} className="review-box">
            <Card.Body>
              <p className="pt-4 pb-0">
                <Image src={starsIcon} className="img-fluid" alt="Stars Icon" />
              </p>
              <p className="review">
                &quot;Customer service is great! Just recently refinanced our
                home and talked with a nice gentleman named Filipe and also a
                sweet lady named Piere (sp?) and they were very helpful.&quot;
              </p>
              <p className="user-review">Janet M.</p>
            </Card.Body>
          </Card>
          <Card as={Col} md={12} lg={4} className="review-box">
            <Card.Body>
              <p className="pt-4 pb-0">
                <Image src={starsIcon} className="img-fluid" alt="Stars Icon" />
              </p>
              <p className="review">
                &quot;We have been with SunWest Mortgage Services for several
                years now and have refinanced (for a lower rate) through them
                without any issues at all.&quot;
              </p>
              <p className="user-review">Jake S.</p>
            </Card.Body>
          </Card>
          {/* </CardDeck> */}
        </Row>

        <Row className="d-flex justify-content-center mt-4 mb-5 pb-5">
          <Col className="text-center" lg={4}>
            <Image className="boxIcon" src={dots} />
          </Col>
        </Row>

        {blogPosts ? (
          <Row
            style={{ backgroundPosition: "inherit" }}
            className="d-flex justify-content-around mt-4 mb-5 pr-3 blog-box"
          >
            <Col md={12} lg={4} className="p-4 mt-4 mb-md-4">
              <h2 className="page-title mb-0 pl-1">Your Resource</h2>
              <h2 className="page-title rect-title mb-4">
                <span className="light-blue-rectangle px-1">Connection</span>
              </h2>
              <p className="sub-title pl-1">
                Get the latest news in the industry
              </p>
              <div className="pl-1">
                <a
                  className="btn blue-btn anchor_blank py-2 px-5"
                  href="https://www.swmc.com/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read More
                </a>
              </div>
            </Col>

            <Col md={12} lg={3} className="d-flex align-items-center">
              <Card className="">
                <Card.Img
                  variant="top"
                  src={`${imgCDNURL}blog/${blogPosts[0].img_name}`}
                  className="rounded img-fluid"
                />
                <Card.Body>
                  <Card.Text className="mb-0" style={{ height: "50%" }}>
                    {blogPosts[0].title
                      .replace("â€œ", '"')
                      .replace("â€", '"')
                      .replace("â€™", "'")
                      .replace("â€“", "-")}
                  </Card.Text>
                  <p className="blog-date">
                    {new Date(blogPosts[0].date).toLocaleString(
                      "en-US",
                      dateOptions
                    )}
                  </p>
                  <a
                    className="post-link"
                    href={`https://www.swmc.com/blog/post-.php?tt=${blogPosts[0].file_name.replace(
                      ".php",
                      ""
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={3} className="d-flex align-items-center">
              <Card>
                <Card.Img
                  variant="top"
                  src={`${imgCDNURL}blog/${blogPosts[1].img_name}`}
                  className="rounded img-fluid"
                />
                <Card.Body>
                  <Card.Text className="mb-0" style={{ height: "50%" }}>
                    {blogPosts[1].title
                      .replace("â€œ", '"')
                      .replace("â€", '"')
                      .replace("â€™", "'")
                      .replace("â€“", "-")}
                  </Card.Text>
                  <p className="blog-date">
                    {new Date(blogPosts[1].date).toLocaleString(
                      "en-US",
                      dateOptions
                    )}
                  </p>
                  <a
                    className="align-self-end post-link"
                    href={`https://www.swmc.com/blog/post-.php?tt=${blogPosts[1].file_name.replace(
                      ".php",
                      ""
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={3} className="d-flex align-items-center">
              <Card>
                <Card.Img
                  variant="top"
                  src={`${imgCDNURL}blog/${blogPosts[2].img_name}`}
                  className="rounded img-fluid"
                />
                <Card.Body>
                  <Card.Text className="mb-0" style={{ height: "50%" }}>
                    {blogPosts[2].title
                      .replace("â€œ", '"')
                      .replace("â€", '"')
                      .replace("â€™", "'")
                      .replace("â€“", "-")}
                  </Card.Text>
                  <p className="blog-date">
                    {new Date(blogPosts[2].date).toLocaleString(
                      "en-US",
                      dateOptions
                    )}
                  </p>
                  <a
                    className="align-self-end post-link"
                    href={`https://www.swmc.com/blog/post-.php?tt=${blogPosts[2].file_name.replace(
                      ".php",
                      ""
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : null}

        <Row className="pt-5">
          <Col className="text-center">
            <Image
              className="triangleImage"
              src={triangles}
              style={{ transform: "scaley(-1) scalex(-1)" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
