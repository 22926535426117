/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IconContext } from "react-icons";
import logoImg from "../images/Homebuyer Connect_H Logo_White.png";
import top50Img from "../images/Top 50 Logo_color-01.png";
import EHOImg from "../images/eho_grey.png";
import "../css/footer.css";

export default function Footer(props) {

  return (
    <div>
      <footer>
        <Container fluid className="px-md-5 py-5">
          <Row className="d-flex justify-content-between pt-3 mb-1 text-sm-center">
            <Col md={6} lg={3}>
              <Image
                src={logoImg}
                alt="Homebuyer connect logo"
                className="img-fluid"
              />
            </Col>
            <Col md={4} lg={3} className="d-none d-sm-none d-md-block">
              <h2 className="text-center pb-1">Stay Connected</h2>
              <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                <div className="d-flex justify-content-center">
                  <a
                    href="https://www.facebook.com/swmchomebuyerconnect/"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaFacebookSquare title="Like us on Facebook!" alt="Facebook icon"/>
                    </span>
                  </a>
                  <a
                    href="https://www.instagram.com/homebuyerconnect/"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaInstagram title="Follow us on Instagram!" alt="Instagram icon"/>
                    </span>
                  </a>
                  <a
                    href="https://twitter.com/SW_HBConnect"
                    target="_blank"
                    className="px-2 twitlogo"
                  >
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA9CAYAAAD1VdrqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPmSURBVHgB7ZqBddsgEEDPfR3AG0Qb1BtUnSDZIMkEdieQOoG9QZIJ3EygdAKnE+BOYG9wParjcVZlCxCSnPf47/FIMJw44E5wAiCRSCQSicRHZQYDg4hzyu4ofaGUUVpwLtlzeqf0S+ez2WwPAzKI4qxsTmnJueEIVskjl805LTg3vFF6pgF4gY8AKb2idMAanVeU7indOLTNKD1Q2qJF6fZwrVDncu6kUbjgmQ+VZwZBiQHI4JqgDpWxFD4jvxAroISp0QqKZbkbckZ4BZjZ38YeXJ+OzFlZzRpGQj9LDPT4ygulCxgZsfR3MCb0wM1USos+FKOuNva0/+wMJkb4lxUMiXAwV/FqYT+j+G2S+bT9DH7opZ1RemhuKdnRLC+0/U1tfoInDbkv8rn095F+f6Q/K+7bI8SGZ1vzdKHOGs/jPSuNN0dxoV7FdXKIjVaYhWdwuaOK691TuuF0y2UKPV5BwoaLjnp516QEIWa7cqx7aCqJ9R5e4+SFqd6zZ/2Knxvv3Y7WkzsdFoSSVUvnNKuO9qXvDIo+LiEWosM+y3TdVBIdvLBQ2mtzwrIP6LAqfYR6C8RTe89FeX7O3qXSGLBkzXKHGJCgBTo4mDNtne1dlCkM3COIVdZ59ncRdsfCbiEAF3sX9hmsNMt86NPXprAlC/sKgTjY+wEj7AaFGfWP2KA9DAQvH+y2d80CeoL2tdtplp9gBPTWkrJvUAcYn4y9U/kbZd+52qhxtVEU1/Ae+wfUe/2tKN9AHVFd4dCnLB9i2HhDXtD73VG2MZ3OTYzLjP/hPIOeoI23a9bG3tkU9MlK/15h+LbTtNtDX4TD6B3pQBGjwwj7+Rb5RSxHaQT23gqiPXQU/H/X+93b2WHMnZsUGLoE0cbonhrlzy32noXYO/sJTbyQmHBwIbNQnusQnr7fF6LcOCnnPTt6niCdwMCTDzocOrA+C7TZe+Fj78JEMogJ2ghM7ljfKK2wY9aEvW8b5ZXLLKJDWCwYsfxcojDeh44+9o4OYbFeuMw62mOsQn/npLits72LQY4/2+Ihredr8bu01ww8Ee2NDCUGQ7Nu6Y8KfZ5v587Z45xtUmGPDQTLVxfSnahrorDjHHDQ7rdLmAi0znO0L7XmwbuplMfAgGSsh0/1fXxzydmN1Ql5I0Lh8DcizEBPdyOi0akCLSVEhAe3RHuTarJv8q3g6R0Vnd9jv6BCU2EtM0owZBDw9IqWWZZOg4D2ileFp3fl4n0WYga70qkVgDqAmItiHWl55/zIZdpWM07Nm40bis68wgCMcZc1g/oeaw72HmvWqLaHekBM/srhqEQikUgkEon/+AtkijqmmV3npwAAAABJRU5ErkJggg==" height={32.5} title="Follow us on Twitter!" alt="Twitter icon"/>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCctMoMIZqh1XESJaOEpUdJA"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaYoutube title="Subscribe on YouTube!" alt="Youtube icon"/>
                    </span>
                  </a>
                  <a
                    href="https://www.linkedin.com/showcase/homebuyer-connect"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaLinkedinIn title="Follow us on LinkedIn!" alt="LinkedIn icon"/>
                    </span>
                  </a>
                </div>
              </IconContext.Provider>
            </Col>
          </Row>

          <Row className="pb-5 d-flex justify-content-around">
            <Col
              md={3}
              lg={3}
              className="pb-5 pb-sm-5 text-center text-md-start"
            >
              <p className="text-white">
              18303 GRIDLEY RD., CERRITOS, CA 90703
                <br />{" "}
                <a href="tel:+15629247884" className="text-white">
                562-924-7884
                </a>
              </p>
              <div className="pt-2">
                 <Button variant="primary" className="dblue clrd-btn text-white clrd-btn text-white ms-0 w-100 py-3"   onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
              </div>
            </Col>
            <Col sm={12} md={2} lg={3} className="footer-list pt-sm-3">
              <h5 className="text-white font-portada pb-md-4 pb-lg-3">
                Quick Links
              </h5>
              <p>
                <Link to="/about">About Us</Link>
              </p>
              {/* <p><Link to="/careers">Careers</Link></p> */}
              <p>
                <a
                  href="https://www.swmc.com/why-sunwest"
                  target="_blank"
                >
                  Testimonials
                </a>
              </p>
              <p>
                <Link to="/contact">Contact Us</Link>
              </p>
            </Col>
            <Col sm={12} md={3} lg={3} className="footer-list pt-md-2">
              <p>
                <a
                  href="https://www.swmc.com/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </p>
              <p>
                <Link to="/loan-programs">Loan&nbsp;Programs</Link>
              </p>
              <p>
              <a
                  href="https://www.swmc.com/angelai/calculators"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mortgage&nbsp;Calculators
                </a>
              </p>
              {/* <p><Link to="/findmarket">Find a Market Manager</Link></p> */}
            </Col>

            <Col xs={9} sm={6} className="d-sm-block d-md-none py-5">
              <h2 className="text-center pb-1">Stay Connected</h2>
              <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                <div className="d-flex justify-content-between">
                  <a
                    href="https://www.facebook.com/swmchomebuyerconnect/"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaFacebookSquare title="Like us on Facebook!" alt="Facebook icon"/>
                    </span>
                  </a>
                  <a
                    href="https://www.instagram.com/homebuyerconnect/"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaInstagram title="Follow us on Instagram!" alt="Instagram icon"/>
                    </span>
                  </a>
                  <a
                    href="https://twitter.com/SW_HBConnect"
                    target="_blank"
                    className="px-2 twitlogo"
                  >
                    <span>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA9CAYAAAD1VdrqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPmSURBVHgB7ZqBddsgEEDPfR3AG0Qb1BtUnSDZIMkEdieQOoG9QZIJ3EygdAKnE+BOYG9wParjcVZlCxCSnPf47/FIMJw44E5wAiCRSCQSicRHZQYDg4hzyu4ofaGUUVpwLtlzeqf0S+ez2WwPAzKI4qxsTmnJueEIVskjl805LTg3vFF6pgF4gY8AKb2idMAanVeU7indOLTNKD1Q2qJF6fZwrVDncu6kUbjgmQ+VZwZBiQHI4JqgDpWxFD4jvxAroISp0QqKZbkbckZ4BZjZ38YeXJ+OzFlZzRpGQj9LDPT4ygulCxgZsfR3MCb0wM1USos+FKOuNva0/+wMJkb4lxUMiXAwV/FqYT+j+G2S+bT9DH7opZ1RemhuKdnRLC+0/U1tfoInDbkv8rn095F+f6Q/K+7bI8SGZ1vzdKHOGs/jPSuNN0dxoV7FdXKIjVaYhWdwuaOK691TuuF0y2UKPV5BwoaLjnp516QEIWa7cqx7aCqJ9R5e4+SFqd6zZ/2Knxvv3Y7WkzsdFoSSVUvnNKuO9qXvDIo+LiEWosM+y3TdVBIdvLBQ2mtzwrIP6LAqfYR6C8RTe89FeX7O3qXSGLBkzXKHGJCgBTo4mDNtne1dlCkM3COIVdZ59ncRdsfCbiEAF3sX9hmsNMt86NPXprAlC/sKgTjY+wEj7AaFGfWP2KA9DAQvH+y2d80CeoL2tdtplp9gBPTWkrJvUAcYn4y9U/kbZd+52qhxtVEU1/Ae+wfUe/2tKN9AHVFd4dCnLB9i2HhDXtD73VG2MZ3OTYzLjP/hPIOeoI23a9bG3tkU9MlK/15h+LbTtNtDX4TD6B3pQBGjwwj7+Rb5RSxHaQT23gqiPXQU/H/X+93b2WHMnZsUGLoE0cbonhrlzy32noXYO/sJTbyQmHBwIbNQnusQnr7fF6LcOCnnPTt6niCdwMCTDzocOrA+C7TZe+Fj78JEMogJ2ghM7ljfKK2wY9aEvW8b5ZXLLKJDWCwYsfxcojDeh44+9o4OYbFeuMw62mOsQn/npLits72LQY4/2+Ihredr8bu01ww8Ee2NDCUGQ7Nu6Y8KfZ5v587Z45xtUmGPDQTLVxfSnahrorDjHHDQ7rdLmAi0znO0L7XmwbuplMfAgGSsh0/1fXxzydmN1Ql5I0Lh8DcizEBPdyOi0akCLSVEhAe3RHuTarJv8q3g6R0Vnd9jv6BCU2EtM0owZBDw9IqWWZZOg4D2ileFp3fl4n0WYga70qkVgDqAmItiHWl55/zIZdpWM07Nm40bis68wgCMcZc1g/oeaw72HmvWqLaHekBM/srhqEQikUgkEon/+AtkijqmmV3npwAAAABJRU5ErkJggg==" height={32.5} title="Follow us on Twitter!" alt="Twitter icon"/>
                    </span>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCctMoMIZqh1XESJaOEpUdJA"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaYoutube title="Subscribe on YouTube!" alt="Youtube icon"/>
                    </span>
                  </a>
                  <a
                    href="https://www.linkedin.com/showcase/homebuyer-connect"
                    target="_blank"
                    className="px-2"
                  >
                    <span>
                      <FaLinkedinIn title="Follow us on LinkedIn!" alt="LinkedIn icon"/>
                    </span>
                  </a>
                </div>
              </IconContext.Provider>
            </Col>

            <Col
              sm={12}
              md={4}
              lg={3}
              className="text-center text-sm-center text-md-center"
            >
              <Row className="justify-content-center">
                <Col xs={4}>
                  <a href="https://sunwestmortgage.com/" target="_blank">
                    <Image
                      src={top50Img}
                      fluid
                      alt="Mortgage Executive Top 50"
                      title="Mortgage Executive Top 50"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pt-5 footer-bottom justify-content-between">
            <Col md={7} lg={7} className="text-center text-md-start">
              <p>
                &copy;{new Date().getFullYear()} Sun West Mortgage Company, Inc.
                All Rights Reserved
              </p>
            </Col>

            <Col
              md={5}
              lg={5}
              className="float-lg-right text-center text-md-right"
            >
              <p>
                <Link to="/sitemap">Sitemap</Link> |{" "}
                <a
                  href="https://www.swmc.com/terms-and-conditions"
                  target="_blank"
                >
                  Terms of Use
                </a>{" "}
                |{" "}
                <a
                  href="https://www.swmc.com/privacy-policy"
                  target="_blank"
                >
                  Privacy policy
                </a>{" "}
                |{" "}
                <a href="https://www.swmc.com/disclaimer" target="_blank">
                  Disclaimers
                </a>
              </p>
            </Col>

            <Col lg={12}>
              <hr className="footer-line" />
            </Col>

            <Col lg={12} className="text-center footer-disclaimer">
              <p>
                For licensing information, go to:{" "}
                <a
                  href="http://www.nmlsconsumeraccess.org/"
                  title="NMLS Consumer Access"
                  target="_blank"
                  className="fw-bold"
                >
                  www.nmlsconsumeraccess.org
                </a>
                . <br />
                Visit{" "}
                <a
                  href="https://www.swmc.com/disclaimer"
                  title="Click Here for full list of license information of Sun West Mortgage Company, Inc."
                  target="_blank"
                  className="fw-bold"
                >
                  www.swmc.com/disclaimer
                </a>{" "}
                for the full list of license information.
                <br /> 
                Please{" "}
                <a
                  href="https://www.swmc.com/TXdis"
                  target="_blank"
                  title="View Texas Complaint Notice and Servicing Disclosure"
                  className="fw-bold"
                >
                  Click Here
                </a>{" "}
                to view Texas Complaint Notice and Servicing Disclosure.
              </p>
              <p>
                Although Sun West Mortgage is approved to conduct business in
                the state of New York, this website has not yet been approved by
                the State of New York Department of Financial Services. For
                properties located in the State of New York, this website can
                not be used to upload an application, please visit{" "}
                <a
                  href="https://www.swmc.com/"
                  target="_blank"
                  className="fw-bold"
                >
                  SWMC.com
                </a>{" "}
                to upload an application.
              </p>
              <Image src={EHOImg} alt="EHO logo" className="img-fluid pt-2" />
            </Col>
          </Row>
        </Container>
      </footer>
       
    </div>
  );
}
