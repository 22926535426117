/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Helmet } from "react-helmet";
import "../css/contact.css";
import triangles from "../images/triangles.png";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";

import ReactGA from "react-ga";

// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//     gtmId: 'GTM-NJX2WC6',
//     dataLayer: {
//       page: 'contact'
//   },
//   dataLayerName: 'PageDataLayer'
//   }

//  Custom CSS
const treasury = {
  opacity: 0,
  position: "absolute",
};
// End of Custom CSS

function trim(theStr) {
  return theStr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
  var retVal = "";
  var okChars = "0123456789";
  var tmpVal = trim(inVal);
  var curChar = "";
  var isDotFound = false;

  if (tmpVal !== "") {
    for (var i = 0; i < tmpVal.length; i++) {
      curChar = tmpVal.charAt(i);

      if (okChars.indexOf(curChar) !== -1) {
        if (!isDotFound && curChar === "." && isDouble) {
          isDotFound = true;
          retVal = retVal + ".";
        }

        if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
          //Do nothing
        } else {
          retVal += curChar;
        }
      }
    }
  }

  return retVal;
}

export default function Contact(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [reasonForContact, setReasonForContact] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showTY, setTYModal] = useState(false);
  const [showFail, setFailModal] = useState(false);
  const [honeyPot, setHoneyPot] = useState(false);
  const [statesOptions, setStatesOptions] = useState("");
  const [consentValue, setConsentValue] = useState(true);

  const [showContactModal, setShowContactModal] = useState(false);
  // stores the input field reference in a way that allows us to conditionally render
  // content or scroll to the element on button click.
  const [node, setNode] = useState(null);

  // watches for changes in the form.
  const formRef = useCallback((node) => {
    // node refers to the name input field in the BorrowerLeadGenerationTool
    setNode(node);
  }, []);

  const handleCloseContactModal = () => setShowContactModal(false);

  const handleTYClose = () => setTYModal(false);
  const handleFailClose = () => setFailModal(false);

  const handleTelephoneNumber = (evt) => {
    //Save Phone number in local storage for Apply

    var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
    var FirstThreeDigit = tmpVal.substring(0, 3);
    var FirstSixDigit = tmpVal.substring(0, 6);

    /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
    var patt =
      /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
    var patt2 = /^800855$/;
    if (patt.test(FirstThreeDigit)) {
      evt.currentTarget.value = "";
      return;
    } else if (patt2.test(FirstSixDigit)) {
      evt.currentTarget.value = "";
      return;
    }

    var curChar = "";

    var areaCode = "";
    var firstThree = "";
    var lastFour = "";
    var extension = "";

    if (tmpVal !== "") {
      for (var i = 0; i < tmpVal.length; i++) {
        curChar = tmpVal.charAt(i);

        if (i < 3) areaCode += curChar;
        if (i < 6 && i > 2) firstThree += curChar;
        if (i < 10 && i > 5) lastFour += curChar;
        if (i > 9) extension += curChar;
      }
    }

    var retVal = "";

    /* 6/13/2020 - Dennis
      Do not put the parentesis if we only have the first three digits of the phone number.
      Once we are past that then start formatting the phone number. This was the main issue
      why the phone number couldn't be blanked out in it's entirety.
    */
    if (areaCode !== "" && firstThree === "") retVal += areaCode;
    else if (areaCode !== "" && firstThree !== "")
      retVal += "(" + areaCode + ")";

    if (firstThree !== "") retVal += " " + firstThree;

    if (lastFour !== "") retVal += "-" + lastFour;

    if (extension !== "") retVal += " x" + extension;
    else setPhoneNumber(retVal);
  };

  function changeClass(ename) {
    ename.target.className = "form-control";
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let contactForm = document.getElementById("contactForm");

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (!honeyPot) {
        setFormLoading(true);

        axios
          .post(process.env.REACT_APP_API_EMAIL, {
            firstName,
            lastName,
            phoneNumber,
            email,
            city,
            state,
            reasonForContact,
            message,
            consentValue,
            honeyPot,
          })
          .then((res) => {
            setTYModal(true);
            setFirstName("");
            setLastName("");
            setEmail("");
            setCity("");
            setState("");
            setReasonForContact("");
            setMessage("");
            setConsentValue(true);
            setPhoneNumber("");
            contactForm.classList.remove("was-validated");
            contactForm.reset();
            setFormLoading(false);
          })
          .catch(function (error) {
            setFailModal(true);
            contactForm.classList.remove("was-validated");
            setFirstName("");
            setLastName("");
            setEmail("");
            setCity("");
            setState("");
            setReasonForContact("");
            setMessage("");
            setPhoneNumber("");
            setConsentValue(true);
            contactForm.reset();
            setFormLoading(false);
          });
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    ReactGA.initialize("UA-187484893-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // TagManager.initialize(tagManagerArgs)
    // TagManager.dataLayer(tagManagerArgs)

    axios
      .post(
        process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
        { companyCode: "SUNWST000", domain: "homebuyerconnect.com" },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setStatesOptions(
          response.data.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))
        );
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Homebuyer Connect</title>
        <link rel="canonical" href="https://homebuyerconnect.com/contact" />
      </Helmet>
      <Container fluid className="hero-contact px-0">
        <div className="triangular h-100">
          <Row className="justify-content-center align-items-center h-100 w-100">
            <Col md={12} lg={6} className="text-center">
              <h1 className="mb-5 text-white">
                <span className="lucky light-blue">Ready</span> to Find Your
                Personal Market Manager?
              </h1>

              <Button
                variant="primary"
                className="dblue clrd-btn text-white"
                onClick={() => {
                  props.setModalShow(true);
                }}
              >
                Get Started
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="pt-5 mt-4 contact-wrapper">
        <Row className="d-flex justify-content-center mb-5 pb-5">
          <Col sm={12} md={12} lg={12} className="text-center">
            <h2 className="page-title mb-3 rect-title d-inline-block">
              <span className="light-blue-rectangle px-1">Here</span> For You
              Every Step of the Way
            </h2>
          </Col>
        </Row>
        <Row className="contact-steps text-center mb-5">
          <Col md={10} lg={5} className="left-column pb-4 px-0 mb-3">
            <h4 className="mb-5" style={{ lineHeight: "2.5" }}>
              Before &amp; During the Loan{" "}
              <span className="luck-md light-blue">Process</span>
            </h4>
            <h5 className="mb-0">Apply Now</h5>
            <p>Get TRU Approved and Start Today</p>

            <Button
              variant="primary"
              className="dblue clrd-btn text-white"
              onClick={() => {
                props.setModalShow(true);
              }}
            >
              Get Started
            </Button>
            <div className="steps-box mt-5 px-3 px-sm-5">
              <h5 className="mb-0">Check Your Loan Status</h5>
              <p className="mb-2">Online or Contact Your Market Manager</p>
              <a
                href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=BRK"
                className="btn btn-outline-dark mb-2"
              >
                Check Online
              </a>
              <h6>
                <span className="fw-bold">Corporate Office</span>
                <br /> <a href="tel:+15629247884">562-924-7884</a> <br />
                18303 GRIDLEY RD., <br /> CERRITOS, CA 90703
              </h6>
            </div>
          </Col>
          <span className="right-border"></span>
          <Col md={10} lg={5} className="right-column pb-4 px-2 px-sm-0">
            <h4 className="mb-5" style={{ lineHeight: "2.5" }}>
              After Your Loan Has{" "}
              <span className="luck-md light-blue">Closed</span>
            </h4>
            <h5 className="mb-0">View Your Loan Information</h5>
            <p>Make a Payment, View Statements &amp; More</p>
            <a
              href="https://sunwestmortgage.myloaninfo.com/loanmanager/login/"
              className="clrd-btn btn text-white ms-0 w-75"
              size="md"
            >
              Log In
            </a>

            <div className="steps-box mt-5 px-3 px-sm-5">
              <h5 className="mb-0">Servicing Questions?</h5>
              <p className="mb-2">Email or Call Us</p>
              <a
                href="mailto:customerservice@swmc.com"
                className="btn btn-outline-orange mb-2"
              >
                CustomerService@swmc.com
              </a>
              <h6>
                <span className="fw-bold">
                  <a href="tel:+18004537884">800-453-7884</a>
                </span>
                <br /> 7am - 7pm PST
              </h6>
            </div>
          </Col>
        </Row>
        <Row className="pt-5 justify-content-center mb-5 pb-5">
          <Col lg={12} className="text-center">
            <h2 className="page-title mb-3 rect-title d-inline-block">
              <span className="light-blue-rectangle px-1">Can&#39;t find</span>{" "}
              what you&#39;re looking for?
            </h2>
          </Col>

          <Col sm={12} md={10} lg={8} className="mt-5">
            <Form
              className="contact-form rounded-lg shadow needs-validation"
              id="contactForm"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <p className="orange-text pb-3">
                Fill out this form and we&#39;ll get back to you as soon as
                possible!
              </p>
              <Row style={treasury}>
                <Form.Group as={Col} sm={5} controlId="quote_name">
                  <strong>
                    <Form.Label>* Get Quote Name: </Form.Label>
                  </strong>
                  <Form.Control
                    type="text"
                    minLength="2"
                    maxLength="12"
                    onChange={(e) => setHoneyPot(true)}
                    data-error="Please fill out this field correctly."
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={5} controlId="quote_code">
                  <strong>
                    <Form.Label>* Get Quote Code: </Form.Label>
                  </strong>
                  <Form.Control
                    type="text"
                    pattern="^[0-9a-zA-Z]{4}$"
                    maxLength="4"
                    onChange={(e) => setHoneyPot(true)}
                    data-error="Please fill out this field correctly."
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col lg={12}>
                  <span className="form-label">Full Name</span>
                </Col>
                <Form.Group as={Col} md="6" controlId="firstName">
                  <Form.Label className="visually-hidden">First name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    required
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    First Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="lastName">
                  <Form.Label className="visually-hidden">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last Name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="userEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="userPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    style={{ textAlign: "left" }}
                    className="contact-form-field"
                    type="text"
                    required
                    placeholder="Your Phone Number"
                    minLength={14}
                    maxLength={21}
                    pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                    onChange={handleTelephoneNumber}
                    onFocus={changeClass.bind(this)}
                    autoComplete="off"
                    value={phoneNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone Number is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    required
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    City is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    value={state}
                    placeholder="Property State"
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    as="select"
                    custom
                    required
                  >
                    <option value="">Your State</option>
                    {statesOptions}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    State is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="12" controlId="reasonContact">
                  <Form.Label>Reason for Contact</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    name="reasonContact"
                    value={reasonForContact}
                    onChange={(e) => {
                      setReasonForContact(e.target.value);
                    }}
                    placeholder="Select"
                    required
                  >
                    <option value="">Select</option>
                    <option value="General Inquiry">General Inquiry</option>
                    <option value="Compliment/Complaint">
                      Compliment/Complaint
                    </option>
                    <option value="Loan Status">Loan Status</option>
                    <option value="Applying">Applying</option>
                    <option value="Join Our Team">Join Our Team</option>
                    <option value="Make a Payment">Make a Payment</option>
                    <option value="Closed Loan">Closed Loan</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select an option
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="12" controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Message"
                    value={message}
                    required
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Message is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="pt-0 text-body text-justify d-flex justify-content-center pt-2 pb-1">
                <Form.Group as={Col} sm={12} className="mb-0 tcp">
                  <p className="consent">
                    <strong>Phone Solicitation Consent</strong>
                  </p>
                  <p className="mb-1 tcp ">
                    By clicking I Consent below, you expressly consent to Sun
                    West Mortgage Company, Inc. making (or allowing to be made
                    on its behalf) a phone call, text message or voicemail
                    transmission to the phone number you entered above for the
                    purpose of soliciting your purchase of consumer goods or
                    services (including using an automated system for the
                    selection or dialing of phone numbers, the playing of a
                    recorded message when a connection is made or the
                    transmission of a pre-recorded voicemail), even if that
                    phone number is a on a national, state or internal Do Not
                    Call Registry. You are not required to click on I Consent
                    below (directly or indirectly) or agree to receive such
                    phone calls, text messages or voicemail transmissions as a
                    condition of obtaining any goods or services from Sun West
                    Mortgage Company, Inc. or its affiliates.
                  </p>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} sm={12} className="checkBoxDisp pb-0">
                  <Form.Check
                    defaultChecked
                    className="checkIConsent fw-bold"
                    id="iConsent"
                    label="I Consent"
                    name="checkIConsent"
                    onClick={() => setConsentValue(true)}
                    type="radio"
                  />
                  <Form.Check
                    className="checkIConsent fw-bold pad-left-iDoNotConsent"
                    id="iDoNotConsent"
                    label="I Do Not Consent"
                    name="checkIConsent"
                    onClick={() => setConsentValue(false)}
                    type="radio"
                  />
                </Form.Group>
              </Row>
              <Row className="text-body">
                <Form.Group as={Col} sm={12} className="mb-0 tcp">
                  <p className="mb-1 tcp">
                    By clicking Send below you agree to Sun West Mortgage
                    Company, Inc.'s ("Sun West"){" "}
                    <a
                      href="https://www.swmc.com/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.swmc.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Security Policy
                    </a>{" "}
                    ; and you agree to Celligence International, LLC.'s
                    ("Celligence"){" "}
                    <a
                      href="https://www.celligence.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.celligence.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Security and Privacy Policy
                    </a>{" "}
                    , all of which you may access by clicking on the "Terms and
                    Conditions" or "Security Policy" web links contained herein.
                  </p>
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-center pt-3">
                {/* <button className="clrd-btn text-white text-center" type="submit">Send</button> */}
                <Button
                  className="clrd-btn text-white text-center ms-0"
                  type="submit"
                >
                  {formLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Send"
                  )}
                  {formLoading ? " Please wait..." : ""}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        <Row className="pt-5 mt-4">
          <Col className="text-center">
            <Image
              className="triangleImage"
              src={triangles}
              style={{ transform: "scaley(-1) scalex(-1)" }}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        centered
        show={showTY}
        onHide={handleTYClose}
        backdrop="static"
      >
        <Modal.Header>
          <h4 className="text-white">Thank You!</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Thank You for contacting Homebuyer Connect! We have received your
            request and one of our team members will get back to you as soon as
            possible.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleTYClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        centered
        show={showFail}
        onHide={handleFailClose}
        backdrop="static"
      >
        <Modal.Body>
          Due to technical difficulties your request could not be processed.
          Please contact <a href="mailto:CSSTeam@swmc.com">CSSTeam@swmc.com</a>.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleFailClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="globalApplyModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showContactModal}
        onHide={handleCloseContactModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Please fill the information below to continue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BorrowerLeadGenerationTool leadSource="NET001" ref={formRef} />
        </Modal.Body>
      </Modal>
    </>
  );
}
